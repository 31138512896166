<template>
  <li class="todo-item">
    <div
      class="todo-title-wrapper"
      @click="$emit('click')"
    >
      <div class="todo-title-area">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-more-vertical drag-icon"
        >
          <circle
            cx="12"
            cy="12"
            r="1"
          />
          <circle
            cx="12"
            cy="5"
            r="1"
          />
          <circle
            cx="12"
            cy="19"
            r="1"
          />
        </svg>
        <div class="title-wrapper">
          <a
            v-if="data.read_at == null"
            class="todo-title"
          ><b>{{ data.data.title }}</b></a>
          <a
            v-else
            class="todo-title"
          >{{ data.data.title }}</a>
        </div>
      </div>
      <div class="todo-item-action">
        <div class="badge-wrapper me-1">
          <span class="badge rounded-pill badge-light-primary">{{ type }}</span>
        </div>
        <small
          class="text-nowrap text-muted me-1"
          style="margin: 5px"
        >{{
          data.updated_at | moment_full
        }}</small>
        <div class="avatar">
          <img
            :src="require('@/assets/images/avatars/user.svg')"
            alt="user-avatar"
            height="32"
            width="32"
          >
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  props: ['data', 'type'],

  setup(props) {
    // const types = {
    //   'App\\Notifications\\StaffAdded': 'Umum',
    //   'App\\Notifications\\StaffApproved': 'Umum',
    //   'App\\Notifications\\VendorAdded': 'Umum',
    //   'App\\Notifications\\VendorApproved': 'Umum',
    //   'App\\Notifications\\SpbApplied': 'Spb',
    //   'App\\Notifications\\SpbApproved': 'Spb',
    //   'App\\Notifications\\SpbRejected': 'Spb',
    //   'App\\Notifications\\SpbReturned': 'Spb',
    // }
    // console.log(props.data.type, types[props.data.type])
    // return {
    //   type: types[props.data.type],
    // }
  },
}
</script>
