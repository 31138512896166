<style scoped>
@import './style.css';
</style>
<template>
  <div
    id="appcontent2"
    class="app-content todo-application"
  >
    <div
      id="isikonten"
      class="content-wrapper container-xxl p-0"
    >
      <div class="content-area-wrapper container-xxl p-0">
        <div class="sidebar-left">
          <div class="sidebar">
            <div class="sidebar-content todo-sidebar">
              <div class="todo-app-menu">
                <div class="add-task" />
                <div
                  class="sidebar-menu-list ps"
                  style="overflow: scroll !important"
                >
                  <div class="list-group list-group-filters">
                    <a
                      class="list-group-item list-group-item-action"
                      :class="{
                        active: category == 'umum',
                      }"
                      @click="category = 'umum'"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="18"
                      />
                      <span class="align-middle"> Notifikasi Umum</span>
                    </a>
                    <a
                      href="#"
                      class="list-group-item list-group-item-action"
                      :class="{ active: category == 'spb' }"
                      @click="category = 'spb'"
                    >
                      <feather-icon
                        icon="TruckIcon"
                        size="18"
                      />
                      <span class="align-middle"> SPB / Unit</span>
                    </a>
                    <a
                      class="list-group-item list-group-item-action"
                      :class="{
                        active: category == 'operation',
                      }"
                      @click="category = 'operation'"
                    >
                      <feather-icon
                        icon="LifeBuoyIcon"
                        size="18"
                      />
                      <span class="align-middle"> Operasional</span>
                    </a>
                    <a
                      class="list-group-item list-group-item-action"
                      :class="{
                        active: category == 'payment',
                      }"
                      @click="category = 'payment'"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="18"
                      />
                      <span class="align-middle"> Pembayaran</span>
                    </a>
                  </div>

                  <div
                    class="ps__rail-x"
                    style="left: 0px; bottom: 0px"
                  >
                    <div
                      class="ps__thumb-x"
                      tabindex="0"
                      style="left: 0px; width: 0px"
                    />
                  </div>
                  <div
                    class="ps__rail-y"
                    style="top: 0px; right: 0px"
                  >
                    <div
                      class="ps__thumb-y"
                      tabindex="0"
                      style="top: 0px; height: 0px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="content-wrapper container-xxl p-0">
            <div class="content-header row" />
            <div class="content-body">
              <div class="body-content-overlay" />
              <div class="todo-app-list">
                <!-- Todo search starts -->
                <div class="app-fixed-search d-flex align-items-center">
                  <div class="sidebar-toggle d-block d-lg-none ms-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-menu font-medium-5"
                    >
                      <line
                        x1="3"
                        y1="12"
                        x2="21"
                        y2="12"
                      />
                      <line
                        x1="3"
                        y1="6"
                        x2="21"
                        y2="6"
                      />
                      <line
                        x1="3"
                        y1="18"
                        x2="21"
                        y2="18"
                      />
                    </svg>
                  </div>
                  <div class="d-flex align-content-center justify-content-between w-100">
                    <!-- <div
                      class="input-group input-group-merge"
                    >
                      <span class="input-group-text"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search text-muted"
                        >
                          <circle
                            cx="11"
                            cy="11"
                            r="8"
                          ></circle>
                          <line
                            x1="21"
                            y1="21"
                            x2="16.65"
                            y2="16.65"
                          ></line></svg
                      ></span>
                      <input
                        v-model="search"
                        type="text"
                        class="form-control"
                        id="todo-search"
                        placeholder="Pencarian"
                        aria-label="Search..."
                        aria-describedby="todo-search"
                      />
                    </div> -->
                  </div>
                  <!-- div class="dropdown">
                                <a href="#" class="dropdown-toggle hide-arrow me-1" id="todoActions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical font-medium-2 text-body"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="todoActions">
                                    <a class="dropdown-item sort-asc" href="#">Sort A - Z</a>
                                    <a class="dropdown-item sort-desc" href="#">Sort Z - A</a>
                                    <a class="dropdown-item" href="#">Sort Assignee</a>
                                    <a class="dropdown-item" href="#">Sort Due Date</a>
                                    <a class="dropdown-item" href="#">Sort Today</a>
                                    <a class="dropdown-item" href="#">Sort 1 Week</a>
                                    <a class="dropdown-item" href="#">Sort 1 Month</a>
                                </div>
                            </div -->
                </div>
                <!-- Todo search ends -->

                <!-- Todo List starts -->
                <div
                  class="todo-task-list-wrapper list-group"
                  style="overflow: scroll !important"
                >
                  <ul
                    id="todo-task-list"
                    class="todo-task-list media-list"
                  >
                    <list-item
                      v-for="item in data"
                      :key="item.id"
                      :type="type"
                      :data="item"
                      @click="showDetail(item)"
                    />
                  </ul>
                  <div
                    class="no-results"
                    :class="{ show: data.length == 0 }"
                  >
                    <h5 v-if="loading">
                      Loading...
                    </h5>
                    <h5 v-else>
                      Tidak ada notifikasi
                    </h5>
                  </div>
                </div>

                <!-- Todo List ends -->
              </div>

              <!-- Right Sidebar starts -->

              <detail-item
                ref="modal"
                :data="selected"
              />

              <!-- Right Sidebar ends -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListItem from './ListItem.vue'
import DetailItem from './DetailItem.vue'

import { BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    ListItem,
    DetailItem,
    BButton,
    BModal,
  },

  data() {
    return {
      data: [],
      loading: false,
      selected: null,
      category: 'umum',
      type: '',
      search: '',
      user: null,
      userType: null,
    }
  },

  watch: {
    search: {
      handler: value => {
        console.log(value)
      },
      deep: true,
    },
    category: {
      handler: function () {
        this.loadData()
      },
      deep: true,
    },
    '$route': function (newId){
      if(newId.query){
        if(newId.query.n){
          this.showDetail(newId.params.data)
        }
      }
      // if(newId.params.notif){
      //   this.showDetail(this.$route.params.data)
      // }
    }
  },

  beforeMount() {
    this.loadData()
  },

  created(){
    if(this.$route.query){
      if(this.$route.query.n && this.$route.params && this.$route.params.data){
        this.showDetail(this.$route.params.data)
      }
    }
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },

  methods: {
    loadData() {
      const category = this.category
      // console.log(category)
      let types
      if (category == 'umum') {
        types = [
          'App\\Notifications\\StaffAdded',
          'App\\Notifications\\StaffApproved',
          'App\\Notifications\\VendorAdded',
          'App\\Notifications\\VendorApproved',
          category,
        ]
      } else if (category == 'spb') {
        types = [
          'App\\Notifications\\SpbApplied',
          'App\\Notifications\\SpbApproved',
          'App\\Notifications\\SpbRejected',
          'App\\Notifications\\SpbReturned',
          category,
        ]
      } else {
        types = [category]
      }
      this.loading = true
      this.$http
        .get('/notification', {
          params: {
            types,
          },
        })
        .then(res => {
          let dataNotif = [];
          if (this.userType == 'vendor'){
              for (let notif of res.data.data){
                if (JSON.stringify(notif).includes(this.user.id)){
                  dataNotif.push(notif);
                }
              }
          }
          else {
            dataNotif = res.data.data
          }
          this.data = dataNotif
          this.type = this.category
          this.loading = false
        })
    },

    showDetail(item) {
      if (item.read_at != null) {
        this.selected = item
        this.$refs.modal.show()
        return
      }

      item.read_at = new Date()

      this.$http.put('/notification/' + item.id + '/read').then(res => {
        this.$store.commit('notification/READ', item.id)

        this.selected = item
        this.$refs.modal.show()
      })
    },
  },
}
</script>
