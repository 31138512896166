<template>
  <b-modal
    v-model="state"
    :hide-footer="true"
    modal-class="modal modal-slide-in sidebar-todo-modal fade "
    content-class="p-0"
    body-class="flex-grow-1 pb-sm-0 pb-3"
    header-class="align-items-center mb-1"
    aria-hidden="true"
    @hidden="reset"
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title">Detail Notifikasi</h5>
      <div
        class="todo-item-action cursor-pointer d-flex align-items-center justify-content-between ms-auto"
        @click="close()"
      >
        <feather-icon icon="XIcon" />
      </div>
    </template>

    <div v-if="data" class="action-tags">
      <div class="mb-1">
        <label for="todoTitleAdd" class="form-label">Judul</label>
        <div id="todoTitleAdd">
          {{ data.data.title }}
        </div>
      </div>
      <div class="mb-1 position-relative">
        <label for="task-assigned" class="form-label d-block">Isi</label>
        <div id="deskripsi" style="white-space: pre-line">
          {{ data.data.body.replace(/\n\n/g, '\n') }}
        </div>
      </div>
      <div class="mb-1 position-relative">
        <label for="task-assigned" class="form-label d-block">Waktu Dikirim</label>
        <div id="deskripsi">
          {{ data.updated_at | moment_full }}
        </div>
      </div>

      <a v-if="checkUserPermission(data)" variant="primary" block href="#" @click="showNotification(data)">Lihat Data</a>
    </div>
  </b-modal>
</template>

<script>
import { showDataNotification } from '@core/comp-functions/ui/notification'

export default {
  props: ['data'],
  data() {
    return {
      state: false,
    }
  },
  methods: {
    show() {
      this.state = true
    },
    reset() {
      this.state = false
    },
    showNotification(notification) {
      // console.log(notification)
      var route = showDataNotification(notification)
      console.log(route)

      if (route != null) this.$router.push(route)
    },
    checkUserPermission(notification){
      var is_viewDetail = true
      var route = showDataNotification(notification)
      if(route.permission){
        var permission = this.$store.state.profile.info.permissions.find(x => x == route.permission)
        if(!permission){
          is_viewDetail = false
        }
      }
      return is_viewDetail
    }
  },
}
</script>
